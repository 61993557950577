import React from 'react';
import ReactHtmlParser from "react-html-parser";
import Fade from "react-reveal/Fade";
import styled from 'styled-components';
import HomestayFAQAccordion from '../components/Accordions/HomestayFAQ';
import { CentreWrapper, GreenButton } from '../components/ButtonElement';
import SEO from '../components/seo';
import { HomestayTestimonialCarousel, InsertedTestimony } from '../components/Testimonials';
import { homestayBanner, homestayGif } from "../images/";
import Layout from "../layout/layout";
import { graphql } from "gatsby"



const VideoContainer = styled.div `
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

`

const ApplicationWrapper = styled.div `
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

const sidebar = [
  {
    to: 'homestayFamily',
    title: 'Homestay family'
  },
  {
    to: 'fees',
    title: 'Fees'
  },
  {
    to: 'faq',
    title: 'FAQ'
  },
]
 
const GifContainer = styled.img `
  width: 100%;
  height: auto;
  align-self: center;

  @media (min-width: 2000px) {
    width: 1600px;
  }
`

const FadeH1 = ({ children }) => {
  return (
    <div style={{ position: "relative", textAlign: "center" }}>
      <Fade>
        <h1>{children}</h1>
      </Fade>
      <br/>
    </div>
  )
}

const Section = styled.div`
  margin: 40px 230px;
  max-width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 1200px) {
    margin: 40px 100px;
  }

  @media (max-width: 900px) {
    margin: 40px 50px;
  }

  @media (max-width: 740px) {
    margin: 9px;
  }
`

export default function Homestay ({data}) {
  const html = data.allWpPage.edges
  const fees = ReactHtmlParser(html[2].node.content)
  const homestayFamily = ReactHtmlParser(html[1].node.content)
  return (
      <div>
        <Layout sidebar={sidebar} pageTitle="Homestay" background={homestayBanner} pageDescription="An authentic British experience where students feel part of the family">
          <SEO title="Homestay | UK Education | International Students | Support" description="Study Links provides a British Homestay experience where students feel part of the family. They are carefully selected to care for and support our students during their stay."/>
          <Section id="homestayFamily">
            <FadeH1>Living in homestay</FadeH1>
            {homestayFamily.map((data, index) => {
            return (
              <Fade>
                <div className="storyData">{data}</div>
              </Fade>
            )
          })}
          <GifContainer src={homestayGif}/>
          </Section>
          <Section id="fees">
            <FadeH1>Fees</FadeH1>
            {fees.map((data, index) => {
            return (
              <Fade>
                <div className="storyData">{data}</div>
              </Fade>
            )
          })}
          <Fade>
            <CentreWrapper>
              <GreenButton href="/contact">Contact Us</GreenButton>
            </CentreWrapper>
            <InsertedTestimony author="Michelle Cheung - Student" quote="I get on really well with my host family and they treat me like their daughter. They make me feel like part of their family and I really enjoy staying with them."/>
          </Fade>
          </Section>
          <Section id="faq">
            <FadeH1>FAQ</FadeH1>
            <HomestayFAQAccordion/>
          </Section>
          <HomestayTestimonialCarousel/>
        </Layout>
      </div>
  )
}

export const STUDYLINKS_HOMESTAY = graphql`
  query studylinks_homestay {
    allWpPage(filter: {title: {in: ["api-studylinks-homestayfees", "api-studylinks-homestay", "api-studylinks-homestayfamily"]}}, sort: {fields: title, order: ASC}) {
      edges {
        node {
          title
          content
        }
      }
    }
  }

`