import React from "react"

const HomestayFAQAccordion = () => {
  return (
    <div id="accordion" class="accordion">
      <div class="accordion-item">
        <div class="accordion-header" id="headingOne">
          <h2 class="mb-0">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              What is a Host Family or Homestay?
            </button>
          </h2>
        </div>

        <div
          id="collapseOne"
          class="accordion-collapse collapse show"
          aria-labelledby="headingOne"
          data-parent="#accordionExample"
        >
          <div class="accordion-body">
            They are families who have been carefully selected by Study Links to
            look after your child and help them feel relaxed, safe and at home
            when out of school. They are interested in getting to know them and
            learning about their culture and it is a lovely way of making new
            friends, speaking English and getting to know more about the British
            way of life.
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <div class="accordion-header" id="headingTwo">
          <h2 class="mb-0">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              Is the Homestay the Guardian?
            </button>
          </h2>
        </div>
        <div
          id="collapseTwo"
          class="collapse"
          aria-labelledby="headingTwo"
          data-parent="#accordionExample"
        >
          <div class="accordion-body">
            No, Study Links is still the Guardian, we arrange for your child to
            stay with a family and Study Links will keep in touch to so see that
            everything is going well.
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <div class="accordion-header" id="headingThree">
          <h2 class="mb-0">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              How will Study Links choose a Homestay for me?
            </button>
          </h2>
        </div>
        <div
          id="collapseThree"
          class="collapse"
          aria-labelledby="headingThree"
          data-parent="#accordionExample"
        >
          <div class="accordion-body">
            When you register with Study Links, we will ask you to send some
            details about your child and any homestay preferences. We look
            carefully at the families we have available to host to see which one
            would be the most suitable.
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <div class="accordion-header" id="headingFour">
          <h2 class="mb-0">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              Can they always stay with the same family?
            </button>
          </h2>
        </div>
        <div
          id="collapseFour"
          class="collapse"
          aria-labelledby="headingFour"
          data-parent="#accordionExample"
        >
          <div class="accordion-body">
            Wherever possible we will try to arrange for them to stay with the
            same family if they are happy there. Sometimes families are not
            available when your child needs accommodation, so we may need to
            place them with another suitable family at these times.
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <div class="accordion-header" id="headingFive">
          <h2 class="mb-0">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFive"
              aria-expanded="false"
              aria-controls="collapseFive"
            >
              When do we need to book homestay?
            </button>
          </h2>
        </div>
        <div
          id="collapseFive"
          class="collapse"
          aria-labelledby="headingFive"
          data-parent="#accordionExample"
        >
          <div class="accordion-body">
            The earlier you let us know your child needs homestay the more
            likely they will be well-matched with the family. You can let us
            know in advance if they will need homestay for the whole year so it
            can be pre-booked. Otherwise we will need to know by the 1st week of
            term.
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <div class="accordion-header" id="headingSix">
          <h2 class="mb-0">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSix"
              aria-expanded="false"
              aria-controls="collapseSix"
            >
              Can my child stay in other accommodation at weekends and during
              holidays?
            </button>
          </h2>
        </div>
        <div
          id="collapseSix"
          class="collapse"
          aria-labelledby="headingSix"
          data-parent="#accordionExample"
        >
          <div class="accordion-body">
            <div>
              Study Links homestays are the best accommodation for your child
              out of school as we have checked them carefully and know that they
              will be safe and comfortable.
            </div>
            <br />
            <div>
              If they wish to make independent arrangements we need to have your
              permission for this in writing, giving the details of who will be
              responsible. Study Links will still act in emergencies.
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <div class="accordion-header" id="headingSeven">
          <h2 class="mb-0">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSeven"
              aria-expanded="false"
              aria-controls="collapseSeven"
            >
              What will a homestay provide?
            </button>
          </h2>
        </div>
        <div
          id="collapseSeven"
          class="collapse"
          aria-labelledby="headingSeven"
          data-parent="#accordionExample"
        >
          <div class="accordion-body">
            <div>
              Our homestay families are all different but you can expect, as a
              minimum, a clean, comfortable home, 3 meals a day and their own
              study bedroom.
            </div>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <div class="accordion-header" id="headingEight">
          <h2 class="mb-0">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseEight"
              aria-expanded="false"
              aria-controls="collapseEight"
            >
              What are the Homestay cancellation deadlines/fees?
            </button>
          </h2>
        </div>
        <div
          id="collapseEight"
          class="collapse"
          aria-labelledby="headingEight"
          data-parent="#accordionExample"
        >
          <div class="accordion-body">
            <div>
              These vary depending on the service you have chosen.  Please refer
              to your contract agreement.
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="accordion-header" id="headingNine">
          <h2 class="mb-0">
            <button
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseNine"
              aria-expanded="false"
              aria-controls="collapseNine"
            >
              How much does homestay accommodation cost?
            </button>
          </h2>
        </div>
        <div
          id="collapseNine"
          class="collapse"
          aria-labelledby="headingNine"
          data-parent="#accordionExample"
        >
          <div class="accordion-body">
            This varies depending on the location, homestay service, and
            guardianship service you have chosen.  Please refer to your contract
            agreement.
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomestayFAQAccordion
